import * as React from 'react'
import { ButtonInput } from './ButtonInput'
import { Wrap } from './Styles'

export interface ButtonOptionType<T = string | number> {
  checked?: boolean
  id: string
  label: string
  value: T
  disabled?: boolean
}

export type InputType = 'checkbox' | 'radio'
export interface ButtonBarProps {
  buttonOptions: ButtonOptionType[]
  className?: string
  name: string
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  type?: InputType
  disabled?: boolean
}

//ToDo: test
//ToDo: update other imports of ButtonBar from flowhub-ui
//Copied from flowhub-ui to add disabled prop
export const ButtonBar = ({
  buttonOptions = [],
  className,
  name,
  onChange,
  type = 'radio',
  disabled,
}: ButtonBarProps) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange && onChange(e)
  }
  return (
    <Wrap role="radiogroup" className={className}>
      {buttonOptions.length > 0 &&
        buttonOptions.map((b) => {
          return (
            <ButtonInput
              checked={b.checked}
              key={`options-${b.id}`}
              id={b.id}
              label={b.label}
              name={name}
              data-testid={name}
              data-intercom-target={name}
              onChange={handleChange}
              options={b}
              type={type}
              value={b.value}
              disabled={disabled || b.disabled}
            />
          )
        })}
    </Wrap>
  )
}
