import { StyledListItem } from './Styles'

export interface ListItemProps {
  display: string
  content?: JSX.Element
}

export const ListItem = (listItemProps: ListItemProps) => {
  return (
    <StyledListItem role="listitem" {...listItemProps}>
      <span>{listItemProps.display}</span>
      {listItemProps.content && listItemProps.content}
    </StyledListItem>
  )
}
