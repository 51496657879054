import {
  RadioButtonGroupInnerWrap,
  RadioButtonGroupLegend,
  RadioButtonGroupWrap,
} from './Styles'

import { RadioButton } from './RadioButton'

export interface ValueOptions {
  label: string
  value: string
  showTextInput?: boolean
}

export interface RadioButtonGroupProps {
  className?: string
  /** label for the radio group, if you dont already have a label */
  headerLabel: string
  /** if the radio buttons are vertical or inline */
  inline?: boolean
  /** optionally specify hook for input onBlur handler */
  onBlur?: () => void
  /** specity hook for input change handler*/
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  /** the configuration of the radio button. the label for the button and the value set when clicked */
  options: {
    label: string
    value: string
    showTextInput?: boolean
  }[]
  /** the form field name */
  radioButtonName: string
  /** the selected value in the group, only one value can be selected */
  selectedValue?: string
}

/**
 *  A radio input group that can be rendered as a column (default) or inline
 */
export const RadioButtonGroup = ({
  className,
  headerLabel,
  inline,
  onBlur,
  onChange,
  options,
  radioButtonName,
  selectedValue,
}: RadioButtonGroupProps) => {
  const radioButtons = options.map((o, i) => {
    return (
      <RadioButton
        key={i}
        name={o.label}
        onBlur={onBlur}
        onChange={onChange}
        radioButtonName={radioButtonName}
        selectedValue={selectedValue}
        {...o}
      />
    )
  })
  return (
    <RadioButtonGroupWrap
      aria-labelledby={radioButtonName}
      inline={inline}
      role="radiogroup"
      className={className}
    >
      {headerLabel && (
        <RadioButtonGroupLegend inline={inline}>
          {headerLabel}
        </RadioButtonGroupLegend>
      )}

      <RadioButtonGroupInnerWrap inline={inline}>
        {radioButtons}
      </RadioButtonGroupInnerWrap>
    </RadioButtonGroupWrap>
  )
}
