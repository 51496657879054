import { animated, useSpring } from 'react-spring'
import { BrandColors } from 'style/BrandColors'
import styled from 'styled-components/macro'

const Message = styled.span`
  color: ${BrandColors.ruby};
  font-family: 'gt-walsheim-reg', sans-serif;
  font-size: 0.725rem;
  padding: 0.25rem 0;
`

const Wrap = styled(animated.div)`
  display: inline-flex;
  align-items: center;
  height: 1.4rem;
  text-align: left;
`

export interface ErrorMessageProps {
  children?: React.ReactText
}

/**
 * Used with `Inputs` in an `InputGroup` to notify user of a validation error
 *
 * `Input` should also have ruby border to match `ErrorMessage` color
 * Imported from FlowhubUI
 */
export const InputErrorMessage = ({ children }: ErrorMessageProps) => {
  const style = useSpring({
    opacity: 1,
    from: { opacity: 0 },
  })
  return (
    <Wrap style={style}>
      {children ? (
        <Message data-test="error_message">• {children}</Message>
      ) : null}
    </Wrap>
  )
}
