import { useState } from 'react'
import { animated, config, useSpring, AnimatedValue } from 'react-spring'

export interface SpinnerProps {
  thickness?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8
  /** ie: 1rem */
  size?: string
  /** style object passed to svg */
  style?: React.CSSProperties
}

type T = any
interface SpringValues {
  x: AnimatedValue<T>
}

interface SvgCircleProps {
  cx: number
  cy: number
  fill: string
  r: number
  strokeLinecap: 'round'
  strokeWidth: number
}

/**
 * Generic spinning loader component
 *
 * Generally used in:
 *  - Buttons
 *
 */
export const Spinner = ({
  thickness = 4,
  size = '1.75rem',
  style,
}: SpinnerProps) => {
  const [finished, setFinished] = useState<boolean>(false)

  const springValues: SpringValues = useSpring({
    config: {
      ...config.molasses,
      duration: 2000,
    },
    x: finished ? 0 : 1,
    from: { x: 0 },
    onRest: () => setFinished(true),
    onStart: () => setFinished(false),
    reset: true,
  })

  const rotate = springValues.x
    .interpolate({ range: [0, 1], output: [0, 360] })
    .interpolate((x: number) => `rotate(${Math.ceil(x)}deg)`)

  const strokeDasharray = 80
  const strokeDashoffset = springValues.x.interpolate(
    [0, 1],
    [strokeDasharray, -strokeDasharray]
  )

  const circleProps: SvgCircleProps = {
    cx: 16,
    cy: 16,
    fill: 'none',
    r: 15 - thickness / 2,
    strokeLinecap: 'round',
    strokeWidth: thickness,
  }

  return (
    <svg
      height={size}
      role="presentation"
      style={style}
      viewBox="0 0 32 32"
      width={size}
    >
      <circle {...circleProps} stroke="rgba(255,255,255,0.75)" />
      <animated.circle
        {...circleProps}
        stroke="currentcolor"
        strokeDasharray={strokeDasharray}
        strokeDashoffset={strokeDashoffset}
        style={{
          transform: rotate,
          transformOrigin: 'center',
        }}
      />
    </svg>
  )
}
