import React, { useEffect, useRef, useState } from 'react'
import { update, isInvalidKey } from './helpers'
import { TextInput } from 'components/FlowhubUIComponents'

const placeholder = '(###) ###-####'

export interface PhoneInputProps {
  disabled?: boolean
  /** adds error style to input  */
  error?: boolean
  initialValue?: string
  /** field name for form */
  name: string
  /** callback for form state (formik) */
  onBlur?: (e: React.FocusEvent) => void
  /** onChange callback that passes in current value */
  onChange: (value: string) => void
}

export interface PhoneInputState {
  cursorPosition: number
  key: string
  phoneNumber: string
}

/**
 * Input that formats phone number
 * allows a max of 10 characters
 * numbers only
 */
export const PhoneInput = ({
  disabled,
  error,
  initialValue,
  name,
  onBlur,
  onChange,
}: {
  disabled?: boolean
  /** adds error style to input  */
  error?: boolean
  initialValue?: string
  /** field name for form */
  name: string
  /** callback for form state (formik) */
  onBlur?: (e: React.FocusEvent) => void
  /** onChange callback that passes in current value */
  onChange: (value: string) => void
}) => {
  const [cursorPosition, _setCursorPosition] = useState(0)
  const cursorPositionRef = useRef(cursorPosition)
  const setCursorPosition = (value: number) => {
    cursorPositionRef.current = value
    _setCursorPosition(value)
  }

  const [state, setState] = useState({
    key: '',
    phoneNumber: initialValue || '',
  })

  const phoneInput = useRef<HTMLInputElement>()
  const [timer, setTimer] = useState(0)

  useEffect(() => {
    // for some reason on non-chrome browsers, the cursor jumps to the end of input
    // even after running requestAnimationFrame so we had to implement a setTimeout for those browsers
    // even though the correct implementation would be requestAnimationFrame
    if (phoneInput.current === document.activeElement) {
      if (navigator.userAgent.search('Chrome') > 0) {
        window.requestAnimationFrame(() => {
          phoneInput.current &&
            phoneInput.current.setSelectionRange(
              cursorPositionRef.current,
              cursorPositionRef.current
            )
        })
      }
      setTimer(
        window.setTimeout(() => {
          phoneInput.current &&
            phoneInput.current.setSelectionRange(
              cursorPositionRef.current,
              cursorPositionRef.current
            )
        }, 10)
      )
    }

    return () => {
      if (timer) {
        clearTimeout(timer)
      }
    }
  }, [timer])

  const handleKeyDown = (evt: React.KeyboardEvent<HTMLInputElement>) => {
    const {
      key,
      currentTarget: { selectionStart: start, selectionEnd: end },
    } = evt

    // if entry is not valid, dont do it
    if (isInvalidKey(key)) return
    const cursor = { start: start || 0, end: end || 0 }

    const { phoneNumber = '', cursorPosition = 0 } = update(
      state.phoneNumber,
      cursor,
      key
    )
    // set state and return value to user
    setCursorPosition(cursorPosition)
    setState({ phoneNumber, key })
    onChange(phoneNumber)
  }

  return (
    <TextInput
      disabled={disabled}
      error={!!error}
      name={name}
      onBlur={onBlur}
      onChange={() => {}}
      ref={phoneInput}
      onKeyDown={handleKeyDown}
      placeholder={placeholder}
      type="tel"
      value={state.phoneNumber}
      initialValue={initialValue}
    />
  )
}
