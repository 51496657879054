import { ChangeEvent } from 'react'
import { Wrap, StyledInput, Label, StyledCheckboxSvg } from './CheckboxStyles'

interface CheckboxFieldProps {
  boldLabel?: boolean
  disabled?: boolean
  label?: any
  margin?: string
  name?: string
  id?: string
  onChange?: (e: string | ChangeEvent<any>) => void
  value: boolean
}
const Checkbox = ({
  boldLabel,
  disabled,
  label,
  margin,
  name,
  id,
  onChange,
  value,
  ...p
}: CheckboxFieldProps) => {
  return (
    <Wrap disabled={!!disabled} margin={margin}>
      <Label bold={boldLabel || false} margin={margin}>
        <StyledInput
          {...p}
          data-testid={name}
          disabled={disabled}
          id={id}
          name={name}
          onChange={onChange}
          type="checkbox"
        />
        <StyledCheckboxSvg checked={value} disabled={disabled} />
        <span className="checkbox-label">{label}</span>
      </Label>
    </Wrap>
  )
}

export { Checkbox }
