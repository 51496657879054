import * as React from 'react'

type HandleScannerSkuType = (sku: Sku) => void

declare global {
  interface Window {
    handleScannerSkuFromSwift?: HandleScannerSkuType
  }
}

type Sku = string

interface ScanTarget extends EventTarget {
  tagName?: string
}
export interface ScanEvent extends KeyboardEvent {
  target: ScanTarget
}

export interface ScannerProps {
  /** a hook that receives the scanned SKU value,
   *  then is called when certain keystrokes are detected,
   *  or after a 500ms duration has elapsed since the last keystroke was detected.
   *
   *  will be called with sku when we receive a valid looking scan
   * */
  handleScannerSku: (sku: Sku) => void
}

/** A component that handles scanner logic from a usb connected scanner.
 *  Add this component where you need the scanner to be available.
 *
 * Does not render any visible components
 *
 *  !Warning - take care of rendering too many scanners, as this might create contention.
 *   See [cashier-ui](https://gitlab.com/Flowhub/cashier) for usage
 */
export class Scanner extends React.Component<ScannerProps, { sku: Sku }> {
  // setting component state
  state = {
    sku: '',
  }

  skuTimeout: number | null = null

  componentDidMount() {
    // make this available in global scope for iOS native Socket SDK code to call

    window.handleScannerSkuFromSwift = this.handleScannerSkuFromSwift
    document.addEventListener('keydown', this.checkAndHandleScannerEvent)
  }

  componentWillUnmount() {
    // remove function when scanner can no longer accept scans
    window.handleScannerSkuFromSwift = undefined
    this.unregisterScannerListener()
  }

  // This method works by basically checking the rate that keys are being pressed.
  // If this method is being called because of scanner events then the clearTimeout
  // will be triggered repeatedly before the setTimeout finishes running. If the
  // setTimeout callback is run that means the scanner is done.

  checkAndHandleScannerEvent = (e: KeyboardEvent) => {
    if (!e.target) return
    const target: ScanTarget = e.target
    // Return if we aren't targeted on the body (aka in an input or something)
    if (target.tagName === 'INPUT' || target.tagName === 'TEXTAREA') {
      return
    }
    if (this.skuTimeout) clearTimeout(this.skuTimeout)
    // If one key and not something more like shift or clear
    // This code used to do String.fromCharCode(e.which) but for some reason - (dash e.which = 189 but was coming through as some weird character)
    if (e.which === 13) {
      this.finishedScanning()
      return
    }
    if (e.key && e.key.length === 1) {
      const value = e.key
      const sku = this.state.sku + value
      this.setState({ sku })
    }
    this.skuTimeout = window.setTimeout(() => {
      this.finishedScanning()
    }, 500)
  }

  finishedScanning() {
    const { handleScannerSku } = this.props
    const sku = this.state.sku.length ? this.state.sku.trim() : ''
    if (sku.length > 4) {
      handleScannerSku(sku)
    }
    this.setState({ sku: '' })
  }

  unregisterScannerListener() {
    document.removeEventListener('keydown', this.checkAndHandleScannerEvent)
  }

  handleScannerSkuFromSwift = (scan: Sku) => this.props.handleScannerSku(scan)

  render() {
    return null
  }
}
