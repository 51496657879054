import { animated, useSpring } from 'react-spring'
import { BrandColors } from 'style/BrandColors'

export interface AnimatedLineProps {
  /** updates focus style */
  hasFocus?: boolean
  /** updates hover style */
  isHovered?: boolean
}

export const AnimatedLine = ({
  hasFocus = false,
  isHovered = false,
}: AnimatedLineProps) => {
  const baseStyles = {
    bottom: 0,
    left: 0,
    right: 0,
    height: '2px',
  }
  const style = useSpring({
    ...baseStyles,
    position: 'absolute',
    background:
      isHovered || hasFocus
        ? BrandColors.mountainMeadow
        : BrandColors.isabelline,
    transform: hasFocus ? 'translateY(0rem)' : 'translateY(1px)',
  })

  return <animated.div style={style} />
}
