import { BrandColors } from 'style/BrandColors'
import styled from 'styled-components/macro'

type GetBigStylesProps = {
  big?: boolean
}
const getBigStyles = ({ big }: GetBigStylesProps) => `
  font-family: inherit;
  font-weight: ${big ? 'normal' : 500};
  font-size: ${big ? 2 : 0.875}rem;
  line-height:  ${big ? 3 : 1.5}rem;
`

export interface LabelProps {
  children: string
  /** changes the font color to inform user it is disabled */
  disabled?: boolean
  /** renders label with large text if true */
  big?: boolean
  /** native html attribute to link label to input, input element must have **id** that matches */
  htmlFor: string
}

/**
 * styled-component: Label for Inputs
 */
export const Label = styled.label.attrs(({ htmlFor }) => ({
  htmlFor,
}))<LabelProps>`
  color: ${({ disabled }) =>
    disabled ? BrandColors.graniteGray : BrandColors.middleRedPurple};
  margin: 0 0 0.25rem 0.1rem;
  padding-right: 1rem;
  user-select: none;
  ${getBigStyles};
`
