import ReactTooltip from 'react-tooltip'

import { StyledToolTip } from './Styles'
import { useState } from 'react'

export interface ToolTipProps {
  /** adds additional styling to wrapper element from styled-components  */
  className?: string
  /** Elements that will activate tooltip */
  children: React.ReactNode
  /** Decides the orientation of the tooltip relative to the element */
  placement?: 'bottom' | 'left' | 'right' | 'top'
  /** Text to display inside tooltip */
  toolTipText?: string
}

/***
 *  This is a wrapper for [react-tooltip](https://github.com/wwayne/react-tooltip#readme)
 */
export const ToolTip = ({
  children,
  className,
  placement = 'left',
  toolTipText = '',
}: ToolTipProps) => {
  const [randomId] = useState(String(Math.random()))
  return (
    <StyledToolTip
      className={className}
      data-tip={toolTipText}
      data-for={randomId} //same as id for ReactTooltip to prevent multiple renders of same tooltip
      data-testid="tooltip-wrapper"
    >
      {children}
      <ReactTooltip
        className="customTheme"
        delayHide={250}
        delayShow={150}
        effect="solid"
        place={placement}
        id={randomId}
      />
    </StyledToolTip>
  )
}
