import * as React from 'react'
import { Adornment, IconWrap, StyledTextInput, Wrap } from './Styles'

export interface AdornmentInputProps
  extends React.HTMLAttributes<HTMLInputElement> {
  disabled?: boolean
  /** Element to display to right of input.
   *
   *  can be a single or array of nodes, but is meant for buttons
   */
  endAdornment?: React.ReactNode
  /**
   * updates style based if any truthy value is passed in
   */
  error?: boolean | string
  name: string
  /** Flowhub-ui Icon to display to left of input */
  startAdornment?: React.ReactNode
}

/**
 *  Enhanced Input component with option for inline adornments
 *
 *  input styles are removed and added to the `Wrap` element, which has a width of 100% to fill available space
 *
 *  if adornment needs added style rules, create a styled component to wrap or update the adornment
 *
 *  other props you would add to an `Input` are passed down to the input.
 */
export const AdornmentInput = ({
  disabled = false,
  endAdornment = null,
  error = '',
  name,
  startAdornment = null,
  ...inputProps
}: AdornmentInputProps) => {
  return (
    <Wrap
      disabled={disabled}
      error={!!error}
      hasEndAdornment={!!endAdornment}
      hasStartAdornment={!!startAdornment}
    >
      {!!startAdornment && (
        <IconWrap data-testid="icon-wrap">{startAdornment}</IconWrap>
      )}
      <StyledTextInput disabled={disabled} name={name} {...inputProps} />
      {!!endAdornment && (
        <Adornment data-testid="adornment">{endAdornment}</Adornment>
      )}
    </Wrap>
  )
}
