// TODO: this file needs some love
// how this value is provided has gone through several iterations,
// and there's almost certainly some redundant/dead/etc code.

export const environment =
  (process?.env?.REACT_APP_RUN_LOCAL === 'true' && 'local') ||
  window?.CONFIG?.ENVIRONMENT ||
  'dev'

const extension = ['dev', 'qa'].includes(environment) ? 'in' : 'com'

const environmentString = environment === 'prod' ? '' : `${environment}.`

//environment to hit if no local url specified for necessary application
//NOTE: this fallback might be a bad idea since logins/auth will no pervade local/dev
const localDefaultFallback = 'dev'

export const serverUrl =
  window?.CONFIG?.SERVER_URL ??
  `https://api.${environmentString}flowhub.${extension}`

//this function is set up so that we can multiplex localhost services and run the frontend locally against them
//in order for this to work, all services that require connection must have an corresponding environment variable set
//a fallback is configured but this might be awkward since auth will not will across envs unless you copy paste your token
export const getServiceUrl = (service) => {
  if (environment === 'local') {
    //each local service must have a corresponding `REACT_APP_{service}_URL` environment var defined
    //to do this, run the maui backend with desired port set with export PORT={port}, then "make run-{service}"
    //set the REACT_APP_{service}_URL to localhost:{port} (or corresponding docker if running in docker)
    let localDefaultServiceurl = `https://api.${localDefaultFallback}.flowhub.${
      ['dev', 'qa'].includes(localDefaultFallback) ? 'in' : 'com'
    }`
    let url =
      `${process?.env[`REACT_APP_${service}_URL`]}/${service}` ||
      `${localDefaultServiceurl}/${service}`

    if (!url.includes('http://')) {
      url = `http://${url}`
    }
    return url
  }
  return `${serverUrl}/${service}`
}
