import * as React from 'react'
import { AnimatedTriangleWrap, Placeholder, Wrap } from './Styles'
import { AnimatedTriangle } from 'components/AnimatedTriangle'

export interface ToggleButtonProps {
  id?: string
  /* specify how the AnimatedTriangle should be rendered */
  isOpen: boolean
  /** hook for focus event - blur  */
  onBlur: (e: React.FocusEvent) => void
  /** input placeholder text */
  placeholder: string
  /** item that is selected */
  selectedItem?: { display: string; value: string; content?: JSX.Element }
  /** Handles additional props passed to single select */
  [arbitrary: string]: any
}

export const ToggleButton = ({
  id,
  isOpen,
  onBlur,
  placeholder,
  selectedItem,
  ...toggleButtonProps
}: ToggleButtonProps) => {
  const [hasFocus, setHasFocus] = React.useState(false)
  const handleFocus = () => setHasFocus(true)
  const handleBlur = () => setHasFocus(false)

  return (
    <Wrap
      id={id || undefined}
      hasFocus={hasFocus}
      isOpen={isOpen}
      onBlur={handleBlur}
      onFocus={handleFocus}
      {...toggleButtonProps}
      data-testid="toggle-button-wrapper"
    >
      {selectedItem && selectedItem.value && selectedItem.display ? (
        <>
          <span>{selectedItem.display}</span>
          {selectedItem.content && selectedItem.content}
        </>
      ) : (
        <Placeholder>{placeholder}</Placeholder>
      )}
      <AnimatedTriangleWrap>
        <AnimatedTriangle rotate={isOpen} size="2rem" />
      </AnimatedTriangleWrap>
    </Wrap>
  )
}

ToggleButton.defaultProps = {
  isOpen: false,
}
