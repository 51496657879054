import { lazy } from 'react'
import { safelyParseJSON } from 'utils/safelyParseJSON'
import { reloadWithoutCache } from 'utils/reloadWithoutCache'

const isChunkLoadError = (e: Error) =>
  `${e?.message}`.startsWith('ChunkLoadError')

const lsKey = 'lazyLoadFailed'

const didLazyLoadFail = () =>
  safelyParseJSON(localStorage.getItem(lsKey) || 'false')

const setLazyLoadFailed = (b: boolean) =>
  localStorage.setItem(lsKey, JSON.stringify(b))

export const lazyLoadComponent: typeof lazy = (importFn) =>
  // @ts-ignore
  lazy(async () => {
    try {
      const component = await importFn()
      setLazyLoadFailed(false)
      return component
    } catch (e) {
      if (!isChunkLoadError || didLazyLoadFail()) throw e
      setLazyLoadFailed(true)
      reloadWithoutCache()
    }
  })
