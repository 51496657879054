import { useState } from 'react'
import { Indicator, Slide, SlideWrap, Tab } from './Styles'
import ReactResizeDetector from 'react-resize-detector'

export interface OptionType {
  displayName: string
  status: 'good' | 'bad' | null
  value: string
}

export interface StateType {
  offset: number
  selected: null | number
  tabWidth: number
}

/** A composed tab component which maintains its own state
 * Selected tab indicator animates by sliding to correct position
 * status in option type determines color of text in button
 */

export const SliderTabs = ({
  options,
  initialValue,
  disabled,
  onChange,
}: {
  /** value before any interaction */
  initialValue?: string
  /** updates styles and passed to native input */
  disabled?: boolean
  /** options to display as tabs */
  options: {
    displayName: string
    status: 'good' | 'bad' | null
    value: string
  }[]
  /** Returns current value to parent comp: (val: boolean) => void */
  onChange: (value: string) => void
}) => {
  const initialSelection = options?.findIndex(
    (x) => x.value.toString() === initialValue
  )
  const [{ offset, selected, tabWidth }, setState] = useState<StateType>({
    offset: 0,
    selected: initialSelection ?? null,
    tabWidth: 0,
  })

  const handleResize = (width?: number, height?: number) => {
    if (width === undefined) {
      return
    }

    const newTabWidth = Math.floor(width / options.length)
    setState(({ offset, selected, tabWidth }) => ({
      offset: selected ? selected * newTabWidth : offset,
      tabWidth: Math.floor(width / options.length),
      selected: selected,
    }))
  }

  const handleSelect =
    (index: number) =>
    (evt: React.MouseEvent<HTMLDivElement>): void => {
      if (disabled) return

      const offset = evt.currentTarget.offsetLeft

      setState({
        offset,
        selected: index,
        tabWidth,
      })

      onChange(options[index].value)
    }

  const tabs = () => {
    return options?.map((x, i) => (
      <Tab
        disabled={disabled}
        key={i}
        on={i === selected ? 'true' : undefined}
        onClick={handleSelect(i)}
        role="button"
        tabWidth={tabWidth}
        {...x}
      >
        <span>{x.displayName}</span>
      </Tab>
    ))
  }

  return (
    <SlideWrap disabled={disabled}>
      <Slide>
        <ReactResizeDetector handleWidth onResize={handleResize} />
        <Indicator
          disabled={disabled}
          offset={offset}
          selected={selected}
          tabWidth={tabWidth}
        />
        {tabs()}
      </Slide>
    </SlideWrap>
  )
}
