import { animated, useSpring } from 'react-spring'

export interface AnimatedTextProps {
  children: React.ReactNode
  /**
   *  - determines if element should be hidden
   *  - also connected to spring style
   */
  hide?: boolean
}

export const AnimatedText = ({ children, hide }: AnimatedTextProps) => {
  const style = useSpring({
    opacity: hide ? 0 : 1,
    visibility: hide ? 'hidden' : 'visible',
  })
  return <animated.div style={style}>{children}</animated.div>
}
