import { useEffect, useRef, useState } from 'react'
import { Switch } from './Switch'
import { ToggleBtn, Wrap } from './styles'
import { useToggle } from 'hooks/useToggle'

export interface ToggleState {
  hasFocus: boolean
}

/** A toggle button input element
 *
 *  typically used to turn a setting on/off
 */
export const Toggle = (props: {
  ariaLabel?: string
  /** Specify an optional className to be added to your Button,
   *  this is automatic if you extend component via styled components
   */
  className?: string
  /** Specify whether the Button should be disabled, or not */
  disabled?: boolean
  /** Specify whether the toggle should be on by default */
  initialOn?: boolean
  /** Specify input name */
  name?: string
  /** Provide a hook that is called when the control is toggled */
  onToggle: (on: boolean) => void
}) => {
  const { ariaLabel, className, disabled, initialOn, onToggle } = props
  const [hasFocus, setHasFocus] = useState(false)
  const [on, toggleOn] = useToggle(initialOn)

  const checkFirstRender = useRef(true)
  useEffect(() => {
    if (checkFirstRender.current) {
      checkFirstRender.current = false
    } else {
      onToggle(on)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [on])

  const handleToggle = () => {
    if (disabled) return
    toggleOn()
  }

  return (
    <Wrap
      className={className}
      disabled={disabled}
      hasFocus={hasFocus}
      tabIndex={-1}
    >
      <ToggleBtn {...props} on={on ? 'on' : undefined} onClick={handleToggle} />
      <Switch
        disabled={disabled}
        ariaLabel={ariaLabel ?? ''}
        onBlur={() => {
          setHasFocus(false)
        }}
        onFocus={() => setHasFocus(true)}
        onChange={handleToggle}
        {...props}
      />
    </Wrap>
  )
}
