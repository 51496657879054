import { Label, ToggleInput } from './styles'

export interface SwitchProps {
  /** description of what toggle does, added for a11y compatibility */
  ariaLabel: string
  /** toggle status :: on/off */
  on?: boolean
  onBlur: (event: React.FocusEvent<HTMLInputElement>) => void
  /** toggle click event handler */
  onChange: (event: React.MouseEvent<HTMLInputElement>) => void
  /** focus / blur handler */
  onFocus: (event: React.FocusEvent<HTMLInputElement>) => void
  /** input name */
  name?: string
}

export const Switch = ({
  ariaLabel,
  on,
  onChange,
  onFocus,
  onBlur,
  ...rest
}: SwitchProps) => (
  <Label aria-label={ariaLabel || 'Toggle'}>
    <ToggleInput
      {...rest}
      checked={on}
      data-test="toggle-input"
      onBlur={onBlur}
      onClick={onChange}
      onFocus={onFocus}
      type="checkbox"
    />
  </Label>
)
