import Downshift from 'downshift'
import { ReactNode } from 'react'

export interface DownshiftMenuProps {
  /** is a render function */
  children: (downshift: any) => ReactNode
  /** defaultValues for list */
  initialSelectedItem?: { display: string; value: string }
  /** hook for change event handler
   *  selected item's value
   */
  onChange: (value: string) => void
}

export const DownshiftMenu = ({
  children,
  initialSelectedItem,
  onChange,
}: DownshiftMenuProps) => {
  return (
    <Downshift
      itemToString={(item) => (item ? item.display : '')}
      onChange={(item) => (item ? onChange(item.value) : () => {})}
      initialSelectedItem={initialSelectedItem}
    >
      {(downshift) => children(downshift)}
    </Downshift>
  )
}
