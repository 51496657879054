import { animated, useSpring } from 'react-spring'

export interface FadeInProps {
  /** [css display property: MDN](https://developer.mozilla.org/en-US/docs/Web/CSS/display) */
  display?: string
  /** content that will be wrapped and fade in */
  children: React.ReactNode
  /** [css height property: MDN](https://developer.mozilla.org/en-US/docs/Web/CSS/height) */
  height?: string
}

export const FadeIn = ({
  display = 'inherit',
  height = 'inherit',
  ...props
}: FadeInProps) => {
  const fadeIn = useSpring({
    display: display,
    height: height,
    opacity: 1,
    width: 'inherit',
    from: { opacity: 0 },
  })
  return <animated.div {...props} style={fadeIn} />
}
