import * as React from 'react'
import { Input, InputWrap, Label } from './Styles'

export interface ButtonInputProps {
  checked?: boolean
  id: string
  label: string
  name: string
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  options: object
  type?: 'checkbox' | 'radio'
  value: string | number
  disabled?: boolean
}

export const ButtonInput = ({
  checked = false,
  id,
  label,
  name,
  onChange,
  type = 'radio',
  value,
  disabled,
  ...rest
}: ButtonInputProps) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange && onChange(e)
  }

  return (
    <InputWrap disabled={disabled}>
      <Input
        {...rest}
        type={type}
        id={id}
        name={name}
        value={value}
        defaultChecked={checked}
        onChange={handleChange}
        disabled={disabled}
      />
      <Label htmlFor={`${id}`}>
        <span>{label}</span>
      </Label>
    </InputWrap>
  )
}
