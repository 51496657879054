import * as React from 'react'

import { Manager, Reference, Popper } from 'react-popper'
import { animated, useSpring } from 'react-spring'

import { PortalWithState } from 'react-portal'
import { MoreOptions } from 'components/Icons'

interface AnimatedContentProps {
  children: React.ReactNode
  onClick: () => void
}
const AnimatedContent = (props: AnimatedContentProps) => {
  const springStyle = useSpring({
    opacity: 1,
    from: { opacity: 0 },
  })
  return <animated.div {...props} style={springStyle} />
}

export type MenuPlacement =
  | 'top-start'
  | 'top'
  | 'top-end'
  | 'right-start'
  | 'right'
  | 'right-end'
  | 'bottom-start'
  | 'bottom'
  | 'bottom-end'
  | 'left-start'
  | 'left'
  | 'left-end'

export interface PopOverProps {
  /** popover content */
  children: React.ReactNode
  /** popover menu placement - react-popper prop */
  menuPlacement?: MenuPlacement
  /** optionally specify an item that triggers the popover toggle, if the default 3 dot menu does not suit your fancy */
  trigger: React.ReactElement
}

/** PopOver component that renders a [react-popper](https://popper.js.org/react-popper/) `Popper` in a React.Portal when the trigger is pressed
 *
 *  Automatically closes if user presses `Esc` or clicks outside of PopOver content, similar to a modal.
 */
export const PopOver = ({
  children,
  menuPlacement = 'bottom-end',
  trigger = <MoreOptions />,
}: {
  /** popover content */
  children: React.ReactNode
  /** popover menu placement - react-popper prop */
  menuPlacement?: MenuPlacement
  /** optionally specify an item that triggers the popover toggle, if the default 3 dot menu does not suit your fancy */
  trigger: React.ReactElement
}) => {
  const renderTrigger = (isOpen: boolean) => {
    return React.cloneElement(trigger, { isOpen })
  }

  return (
    <PortalWithState closeOnEsc closeOnOutsideClick>
      {({ closePortal, isOpen, openPortal, portal }) => {
        const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
          e.preventDefault()
          if (e.key === 'Enter' || e.key === ' ') {
            isOpen ? closePortal() : openPortal()
          }
        }
        return (
          <Manager>
            <Reference>
              {({ ref }) => (
                <div
                  aria-expanded={isOpen}
                  aria-haspopup={true}
                  onClick={openPortal}
                  onKeyDown={handleKeyDown}
                  ref={ref}
                  role="button"
                  tabIndex={0}
                  style={{ outline: 'none' }}
                >
                  {renderTrigger(isOpen)}
                </div>
              )}
            </Reference>
            {portal(
              <Popper placement={menuPlacement}>
                {({ placement, ref, style }) => (
                  <div data-placement={placement} ref={ref} style={style}>
                    <AnimatedContent onClick={closePortal}>
                      {children}
                    </AnimatedContent>
                  </div>
                )}
              </Popper>
            )}
          </Manager>
        )
      }}
    </PortalWithState>
  )
}
