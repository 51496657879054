import * as React from 'react'

import { IconWrap, Input, Wrap } from './Styles'
import { SearchIcon } from './SearchIcon'
import { AnimatedLine } from 'components/AnimatedLine'
import { FadeIn } from 'components/FadeIn'

export interface SearchBarProps {
  /** specify a defaultValue for the input */
  defaultValue?: React.ReactText
  /** disabled */
  disabled?: boolean
  /** onChange handler, passes back updated value */
  onChange: (value: string) => void
  /** onFocus handler, is called if handleFocus event.type is 'focus' */
  onFocus?: () => void
  /** input placeholder */
  placeholder?: string
  searchBarRef?: React.RefObject<HTMLInputElement>
  /**
   * @deprecated
   * default value for input
   * use `defaultValue` instead
   */
  value?: React.ReactText
}

/** A HTML search input `input[type="search"]` with custom styles
 *
 * Used to search inventory in [cashier](https://gitlab.com/Flowhub/cashier)
 */
export const SearchBar = ({
  defaultValue = '',
  disabled = false,
  onChange,
  onFocus,
  value = '',
  placeholder = 'Search by keyword...',
  searchBarRef,
  ...rest
}: SearchBarProps) => {
  const [hasFocus, updateFocus] = React.useState(false)
  const [isHovered, updateHover] = React.useState(false)
  /* eslint-disable */
  const inputRef = searchBarRef || React.useRef<HTMLInputElement>(null)

  const hasValue =
    inputRef && !!inputRef.current ? !!inputRef.current.value.length : false

  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    updateFocus(e.type === 'focus')
    if (typeof onFocus === 'function') onFocus()
  }

  const handlePointer = (e: React.PointerEvent<HTMLDivElement>) => {
    updateHover(e.type === 'pointerenter')
  }

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    onChange(e.target.value)

  return (
    <Wrap
      data-testid="wrap"
      disabled={disabled}
      hasFocus={hasFocus}
      isHovered={isHovered}
      onPointerEnter={handlePointer}
      onPointerLeave={handlePointer}
      {...rest}
    >
      <FadeIn>
        <IconWrap hasValue={hasValue}>
          <SearchIcon addStroke={hasFocus || hasValue} />
        </IconWrap>

        <Input
          defaultValue={defaultValue || value}
          disabled={disabled}
          onBlur={handleFocus}
          onChange={handleOnChange}
          onFocus={handleFocus}
          placeholder={placeholder}
          ref={inputRef}
          role="search"
        />
      </FadeIn>
      <AnimatedLine hasFocus={hasFocus} isHovered={isHovered} />
    </Wrap>
  )
}
