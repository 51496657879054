import { Tab } from './Tab'
import { TabList } from './Styles'

export type TabColorType = 'primary' | 'secondary'
export interface TabType {
  display: string | React.ReactNode
  id: string
}
export interface TabsProps {
  /** id of tab that is active, which renders an active tab indicator */
  activeTab: string
  /** class name of tab that is active */
  className?: string
  /** Specify the color theme of the selected tab indicator */
  color?: 'primary' | 'secondary'
  /** a hook that passes back the id of the tab, when a non active tab is selected */
  onSelect: (id: string) => void
  /** tabs to render */
  tabs: {
    display: string | React.ReactNode
    id: string
  }[]
}

/**
 *  A composed Tabs component
 *
 *  Mainly used in local or global navigation.
 *
 *  activeTab state must be maintained by parent component.
 */
export const Tabs = ({
  activeTab,
  className,
  onSelect,
  tabs = [],
  color = 'primary',
}: TabsProps) => {
  return (
    <TabList className={className} data-test="tablist" role="tablist">
      {tabs.map(({ display, id }) => (
        <Tab
          active={id === activeTab}
          color={color}
          key={id}
          name={id}
          onSelect={() => onSelect(id)}
        >
          {display}
        </Tab>
      ))}
    </TabList>
  )
}
