import { Nav, Page, Wrap } from './Styles'
import { ArrowBack, ArrowNext } from 'components/Icons'

export interface PaginationProps {
  /** passed in by styled-components when extending component (wrapper only) */
  className?: string
  /** specifies the current page being viewed by user, adds colored circle around that number. */
  currentPage: number
  /** specify the total number of pages in the collection (total items / items per page) */
  totalPages: number
  /** hook which notifies parent component of the page selected by the user */
  onClick: (page: number) => void
}

/**
 *  Pagination component which allows user to page through large data sets.
 *  State management should be handled by parent component
 */
export const Pagination = ({
  className,
  currentPage,
  onClick,
  totalPages,
}: PaginationProps) => {
  const handleClick = (page: number) => {
    onClick && onClick(page)
  }

  // Number of elements to the left and right of the current page. Hardcoded to 3 to meet specs
  const pageNeighbors = 3
  // Adds pages to the back of the range if the range is less than 1
  const backBuffer =
    currentPage - pageNeighbors < 1 ? pageNeighbors - currentPage + 1 : 0
  // Adds pages to the front of the range if the range goes above the total pages
  const frontBuffer =
    currentPage + pageNeighbors >= totalPages
      ? currentPage + pageNeighbors - totalPages
      : 0
  // Calculates the start of the range
  const startPage =
    currentPage - pageNeighbors - frontBuffer > 1
      ? currentPage - pageNeighbors - frontBuffer
      : 1
  // Calculates the end of the range
  const endPage = Math.min(currentPage + pageNeighbors + backBuffer, totalPages)

  const range = (start: number, stop: number) =>
    new Array(stop - start + 1).fill(start).map((x, i, arr) => {
      let disabled = false
      let page = x + i
      if (i === 0) {
        // First element is always 1
        page = 1
      } else if (i === arr.length - 1) {
        // Last element is always the last page
        page = totalPages
      } else if (
        (i === 1 && page !== 2) ||
        (i === arr.length - 2 && page !== totalPages - 1)
      ) {
        // Replaces 2nd and 2nd to last number with '...' if that number is not 2 or the totalPage number - 1
        page = '...'
        disabled = true
      }
      return (
        <Page
          aria-label={`${page}`}
          isCurrentPage={currentPage === page}
          disabled={disabled}
          key={i}
          onClick={() => handleClick(page)}
        >
          {page}
        </Page>
      )
    })

  return (
    <Wrap className={className}>
      <Nav
        aria-label="Back"
        icon={<ArrowBack />}
        disabled={currentPage === 1}
        onClick={() => handleClick(currentPage - 1)}
      />
      {range(startPage, endPage)}
      <Nav
        aria-label="Next"
        disabled={currentPage === totalPages}
        icon={<ArrowNext />}
        iconRight={true}
        onClick={() => handleClick(currentPage + 1)}
      >
        Next
      </Nav>
    </Wrap>
  )
}
