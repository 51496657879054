import * as React from 'react'

import InputBaseComponentProps from '../inputBase'
import {
  ErrorMessage,
  InputGroup,
  Label,
  TextInput,
} from 'components/FlowhubUIComponents'

export interface TextFieldProps {
  /** passed to native input disabled attr */
  disabled?: boolean
  /** error text displays below input */
  error?: string
  /** text that displays in label */
  label: string
  /** should render large label? */
  labelSize?: 'reg' | 'big'
  /** camelCase name that gets passed in to input id and label for */
  name: string
  /** true if input will not have error message,
   * by default, an empty error message reserves the space in the layout,
   * so the content does not shift when error message is displayed
   */
  noError?: boolean
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  /** native placeholder passed through */
  placeholder?: string
}

/**
 *  A full input field composed of InputGroup, Label, TextInput and ErrorMessage
 *  Use this if you dont want to build a form field in your app.
 *
 *  File name is FHTextField because it was imported from FHUI, a legacy Flowhub component library, and conflicts with the existing TextField that is for our forms.
 */
export const TextField = ({
  disabled = false,
  error,
  label = 'reg',
  labelSize,
  name,
  noError,
  ...rest
}: TextFieldProps & InputBaseComponentProps) => {
  return (
    <InputGroup aria-label={name} data-testid="input-group">
      {!!label && (
        <Label
          big={labelSize === 'big'}
          children={label}
          disabled={disabled}
          htmlFor={name}
        />
      )}
      <TextInput disabled={disabled} error={!!error} name={name} {...rest} />
      {!noError && <ErrorMessage>{error}</ErrorMessage>}
    </InputGroup>
  )
}
