import * as React from 'react'
import { useSpring } from 'react-spring'
import { AnimatedMenu } from './Styles'

export interface MenuProps {
  innerRef: React.RefObject<HTMLUListElement>
  isOpen?: boolean
}

export const Menu = ({ isOpen = false, innerRef, ...props }: MenuProps) => {
  const style = useSpring({
    opacity: isOpen ? 1 : 0,
    transform: isOpen ? 'translateY(0%)' : 'translateY(-20%)',
  })
  return <AnimatedMenu ref={innerRef} {...props} style={style} />
}
