import { RadioButtonLabel, RadioButtonWrap, StyledRadioButton } from './Styles'
import { TextInput } from 'components/FlowhubUIComponents'

export interface RadioButtonProps {
  /** label for the button */
  label: string
  /** name for the button */
  name: string
  /** hook for managing form state onBlur */
  onBlur?: () => void
  /** hook for when value is selected */
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  radioButtonName: string
  selectedValue?: string
  /** whether there is an input that goes along with the button */
  showTextInput?: boolean
  /** if you chose to showTextInput, you must provide a hook for the change handler */
  textInputOnChange?: () => void
  /** specify your placeholder text */
  textInputPlaceholder?: string
  /** specify a value if you want it to be prefilled */
  textInputValue?: string
  /** selected value in the group */
  value?: string
}

/** a dynamic single radio input component
 *  usally used in `RadioButtonGroup`
 */
export const RadioButton = ({
  label,
  name,
  value,
  onChange,
  onBlur,
  radioButtonName,
  selectedValue,
  showTextInput,
  textInputOnChange,
  textInputPlaceholder,
  textInputValue,
}: RadioButtonProps) => {
  return (
    <RadioButtonWrap>
      <RadioButtonLabel htmlFor={value}>
        <StyledRadioButton
          aria-checked={selectedValue === value}
          checked={selectedValue === value}
          id={value}
          name={radioButtonName || 'value'}
          onBlur={onBlur}
          onChange={onChange}
          type="radio"
          value={value}
        />
        {label}
        {showTextInput && (
          <TextInput
            aria-label={name}
            autoComplete="off"
            disabled={selectedValue !== value}
            name={name}
            onChange={textInputOnChange}
            placeholder={textInputPlaceholder}
            style={{ marginLeft: '1rem', width: '100%' }}
            value={textInputValue}
          />
        )}
      </RadioButtonLabel>
    </RadioButtonWrap>
  )
}
