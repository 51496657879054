import * as React from 'react'
import { DownshiftMenu } from './DownshiftMenu'
import { ListItem } from './ListItem'
import { ToggleButton } from './ToggleButton'
import { Menu } from './Menu'
import { Label, Wrap } from './Styles'

interface Option {
  display: string
  value: number | string
  content?: JSX.Element
}

export interface SingleSelectProps {
  /** for creating a styled component, and adding styles to wrap */
  className?: string
  initialSelectedItem?: { display: string; value: string }
  /* label, if you havent want to use the built in version */
  label: string
  /** hook for change event on input, which returns the selected item\'s value */
  onChange: (value: string) => void
  /* options for dropdown */
  options: Option[]
  /** input placeholder */
  placeholder?: string
  /** input name */
  name?: string
  onBlur?: any
}

/** need to get the real defs from Downshift */
interface DownshiftRenderProps {
  [index: string]: any
}

/**
 *  Single Select Dropdown similar where only 1 item can be selected at a time
 *
 * the selected item populates the input field
 */
export const SingleSelect = ({
  className,
  initialSelectedItem,
  label,
  onChange,
  options,
  placeholder,
  name,
}: SingleSelectProps) => {
  const handleKeyPress = (
    event: React.KeyboardEvent,
    itemToString: (option: Option) => string,
    highlightedIndex: number,
    setHighlightedIndex: (index: number) => void
  ) => {
    const keyDownValue = event.key
    if (keyDownValue === 'Tab') return

    event.preventDefault()
    event.stopPropagation()
    let indexValue = 0
    indexValue = options.findIndex((item) => {
      let dropDownItem = ''
      if (itemToString) {
        dropDownItem = itemToString(item as Option)
      } else {
        dropDownItem = String(item)
      }
      const firstCharacter = dropDownItem.trim()[0].toLowerCase()
      return firstCharacter === keyDownValue
    })

    if (indexValue >= 0 && indexValue !== highlightedIndex) {
      setHighlightedIndex(indexValue)
    }
  }

  return (
    <DownshiftMenu
      initialSelectedItem={initialSelectedItem}
      onChange={onChange}
    >
      {({
        getItemProps,
        getLabelProps,
        getMenuProps,
        getRootProps,
        getToggleButtonProps,
        highlightedIndex,
        isOpen,
        itemToString,
        selectedItem,
        setHighlightedIndex,
      }: DownshiftRenderProps) => {
        return (
          <Wrap className={className} {...getRootProps({ role: 'listbox' })}>
            {label && (
              <Label {...getLabelProps()} htmlFor={label}>
                {label}
              </Label>
            )}
            <ToggleButton
              id={label || 'single-select-input'}
              {...getToggleButtonProps({
                isOpen,
                placeholder,
                selectedItem,
                onKeyDown: (event: React.KeyboardEvent) =>
                  handleKeyPress(
                    event,
                    itemToString,
                    highlightedIndex,
                    setHighlightedIndex
                  ),
              })}
              name={name}
            />
            <Menu {...getMenuProps({ isOpen, refKey: 'innerRef' })}>
              {isOpen &&
                options.map((item, index) => (
                  <ListItem
                    key={item.value}
                    {...getItemProps({
                      item,
                      isActive: highlightedIndex === index,
                      display: item.display,
                    })}
                    content={item.content}
                  />
                ))}
            </Menu>
          </Wrap>
        )
      }}
    </DownshiftMenu>
  )
}
