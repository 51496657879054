import * as React from 'react'
import { useSpring, useTransition, config } from 'react-spring'
import { IndicatorWrap, Line, StyledTab } from './Styles'
import { TabColorType } from '.'

interface AnimatedTabProps {
  active?: string
  children: React.ReactNode
  disabled?: boolean
  onBlur: (e: React.FocusEvent<HTMLDivElement>) => void
  onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  onFocus: (e: React.FocusEvent<HTMLDivElement>) => void
  onKeyPress: (e: React.KeyboardEvent<HTMLDivElement>) => void
  onMouseDown: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  onPointerEnter: (e: React.PointerEvent<HTMLDivElement>) => void
  onPointerLeave: (e: React.PointerEvent<HTMLDivElement>) => void
  role: 'tab'
  tabIndex: number
}
const AnimatedTab = (props: AnimatedTabProps) => {
  const style = useSpring({
    opacity: 1,
    from: { opacity: 0 },
    delay: 250,
  })
  return <StyledTab {...props} style={style} />
}

export interface IndicatorProps {
  active?: boolean
  color: TabColorType
  type?: 'focus'
}

const Indicator = ({ active, type, color }: IndicatorProps) => {
  const transitions = useTransition(active, null, {
    config: config.default,
    from: { transform: 'translateX(-70%)' },
    enter: { transform: 'translateX(0%)' },
    leave: { transform: 'translateX(130%)' },
  })
  return (
    <>
      {transitions.map(
        ({ item, key, props }) =>
          item && (
            <Line
              data-test="tab-active-indicator"
              color={color}
              focus={type === 'focus' ? 'true' : undefined}
              key={key}
              style={props}
            />
          )
      )}
    </>
  )
}

export interface TabProps {
  active: boolean
  children: string | React.ReactNode
  color: TabColorType
  disabled?: boolean
  name: string
  /** event handler sends selected name back */
  onSelect: () => void
}

export const Tab = ({
  active,
  children,
  disabled,
  name,
  onSelect,
  color,
}: TabProps) => {
  const [hasFocus, setFocus] = React.useState(false)

  const handleEvent = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (disabled) return
    if (e.type === 'click' && !active) {
      setFocus(true)
      onSelect()
    }
  }

  const handleKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (disabled) return
    const keyCodes = {
      enter: 13,
      space: 32,
    }

    if (Object.values(keyCodes).includes(e.charCode)) {
      onSelect()
    }
  }

  const handleFocus = (
    e: React.FocusEvent<HTMLDivElement> | React.SyntheticEvent<HTMLDivElement>
  ) => {
    if (disabled) return
    /// synthetic event : ['blur', 'mouseleave'] will setFocus to false
    setFocus(['focus', 'mouseenter', 'pointerenter'].includes(e.type))
  }

  return (
    <AnimatedTab
      active={active ? 'active' : undefined}
      aria-selected={active}
      data-test={`${name}_tab`}
      disabled={disabled}
      onBlur={handleFocus}
      onClick={handleEvent}
      onFocus={handleFocus}
      onKeyPress={handleKeyPress}
      onMouseDown={handleEvent}
      onPointerEnter={handleFocus}
      onPointerLeave={handleFocus}
      role="tab"
      tabIndex={active ? -1 : 0}
    >
      {children || name}
      <IndicatorWrap>
        <Indicator
          active={active || hasFocus}
          color={color}
          type={!active && hasFocus ? 'focus' : undefined}
        />
      </IndicatorWrap>
    </AnimatedTab>
  )
}
